import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Button,
  Table,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import swal from "sweetalert";

export const About = () => {
  const [data, setdata] = useState();

  const [aboutTrek, setAbout] = useState("");
  const [image, setImage] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [updateaboutTrek, setUpdateAbout] = useState("");
  const [updateimage, setUpdateImage] = useState("");
  const [updatedId , setupdatedId] = useState("")
  const [updateshow, setUpdateShow] = useState(false);
  const updatehandleClose = () => setUpdateShow(false);
  const updatehandleShow = () => setUpdateShow(true);

  useEffect(() => {
    getdata();
  }, []);

  const getdata = () => {
    axios.get(`${process.env.REACT_APP_API}/about`).then(function (res) {
      setdata(res.data);
    });
  };

  const DeleteList = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API}/about/${id}`)
      .then(function (res) {
        getdata();
        swal({
          title: "Delete",
          text: "Delete Successfully!!! ",
          icon: "success",
        });
      });
  };

  const HandelSubmit = async (event) => {
    event.preventDefault();
    var formData = new FormData();
    formData.append("aboutTrek", aboutTrek);
    for (let index = 0; index < image.length; index++) {
      formData.append("image", image[index]);
    }
    axios
      .post(`${process.env.REACT_APP_API}/about`, formData, {
        headers: {
          "content-type": "multipart/formdata",
        },
      })
      .then((res) => {
        getdata();
        swal({
          title: "Add",
          text: "Trek added Successfully!!! ",
          icon: "success",
        });
        setAbout();
        setImage();
        handleClose();

      });
  };


  const  HandelShowUpdate = (el) => {
    updatehandleShow()
    setUpdateAbout(el.aboutTrek)
    setUpdateImage(el.updateimage)
    setupdatedId(el._id)
  }


  const UpdateHandelSubmit = async (event) => {
    event.preventDefault();

  

    var formData = new FormData();
    formData.append("aboutTrek", updateaboutTrek);
    for (let index = 0; index < updateimage.length; index++) {
      formData.append("image", updateimage[index]);
    }
    
    
    axios
      .patch(`${process.env.REACT_APP_API}/about/${updatedId}`, formData, {
        headers: {
          "content-type": "multipart/formdata",
        },
      })
      .then((res) => {
        getdata();
        swal({
          title: "Add",
          text: "Trek added Successfully!!! ",
          icon: "success",
        });
        setAbout();
        setImage();
        updatehandleClose();

      });
  };

  return (
    <Container style={{ marginTop: "50px" }}>
      <Row>
        <Col style={{ padding: "0px" }}>
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <h1
              style={{
                fontFamily: "DM Sans",
                fontSize: 20,
                textAlign: "center",
                marginBottom: 20,
                fontWeight: "bold",
              }}
            >
              About{" "}
            </h1>
            <Button
              onClick={handleShow}
              variant="outline-success"
              className=" mb-2 firstUperCase"
            >
              Add about
            </Button>
          </div>

          <Table
            bordered
            style={{ fontFamily: "DM Sans", fontSize: "12px" }}
            responsive
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>About</th>
                <th>Image</th>
                <th>Update </th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((el, indx) => (
                <tr key={indx}>
                  <td>{indx++ + 1}</td>
                  <td>{el.aboutTrek}</td>
                  <td>{el.image}</td>

                  <td>
                    <Button
                      onClick={() => HandelShowUpdate(el)}
                      
                      variant="outline-success"
                    >
                      edit
                    </Button>
                  </td>

                  <td>
                    <Button
                      onClick={() => DeleteList(el._id)}
                      variant="outline-danger"
                    >
                      delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add About</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>About</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                rows={3}
                placeholder="xyz"
                onChange={(e) => {
                  setAbout(e.target.value);
                }}
                value={aboutTrek}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                placeholder="xyz"
                onChange={(e) => {
                  setImage(e.target.files);
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={HandelSubmit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={updateshow} onHide={updatehandleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update About</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>About</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                rows={3}
                placeholder="xyz"
                onChange={(e) => {
                  setUpdateAbout(e.target.value);
                }}
                value={updateaboutTrek}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Image</Form.Label>
              <Form.Control
                type="file"
                placeholder="xyz"
                onChange={(e) => {
                  setUpdateImage(e.target.files);
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={UpdateHandelSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
