import React, { useState, useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import {
  div,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Container,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { FiAlignRight, FiXCircle, FiChevronDown } from "react-icons/fi";
import "../Style/navbar.css";
import axios from "axios";

export const Navbarmenu = () => {
  const location = useLocation();
  const [userId, setUserID] = useState(localStorage.getItem("userId"));
  const [userData, setUserData] = useState();

  const getUserdata = () => {
    axios
      .get(`${process.env.REACT_APP_API}/users/${userId}`, {
        category: "latest",
      })
      .then(function (res) {
        setUserData(res.data);
      });
  };

  const UserHandelDelete = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
  };

  useEffect(() => {
    getUserdata();
    setUserID(localStorage.getItem("userId"));
  }, [location, userId]);

  return (
    <>
      <Navbar bg="light" expand="lg" className="px-3">
        <Container fluid>
          <Navbar.Brand href="#home">
            <Link to={"/"} className="navbar-brand p-0">
              {" "}
              <img
                src="/kumgarh.png"
                className="d-inline-block align-top"
                alt="not found"
                style={{ width: "200px", height: "50px" }}
              />
            </Link>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown title="Trek" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  {" "}
                  <Link className="text-decoration-none" to="/treks/all">
                    All Treks
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {" "}
                  <Link className="text-decoration-none" to="/treks/latest">
                    Latest Trek
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {" "}
                  <Link className="text-decoration-none" to="/treks/popular">
                    Popular Trek
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {" "}
                  <Link className="text-decoration-none" to="/treks/upcoming">
                    Upcoming Trek
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {" "}
                  <Link className="text-decoration-none" to="/treks/bestplace">
                    Best Places Trek
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Season Trek" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  {" "}
                  <Link className="text-decoration-none" to="/treks/winter">
                    Winter Trek
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {" "}
                  <Link className="text-decoration-none" to="/treks/summer">
                    Summer Trek
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {" "}
                  <Link className="text-decoration-none" to="/treks/autumn">
                    Autumn Trek
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {" "}
                  <Link className="text-decoration-none" to="/treks/all">
                    All Season Trek
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Regional Trek" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  {" "}
                  <Link className="text-decoration-none" to="/treks/kumaon">
                    Kumaon Trek
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {" "}
                  <Link className="text-decoration-none" to="/treks/garhwal">
                    Garhwal Trek
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {" "}
                  <Link className="text-decoration-none" to="/treks/himachal">
                    Himachal Trek
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>

              <Nav.Link href="#link">School/Collage</Nav.Link>

              <NavDropdown title="Char Dham" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  {" "}
                  <Link className="text-decoration-none" to="/treks/chardham">
                    Char Dham
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  {" "}
                  <Link className="text-decoration-none" to="/treks/chardham">
                    Do Dham
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>

              <Link
                style={{
                  textDecoration: "none",
                  color: "gray",
                  marginTop: "9px",
                  marginRight: "9px",
                }}
                to={"contactUs"}
              >
                Contact Us
              </Link>
              <Link
                style={{
                  textDecoration: "none",
                  color: "gray",
                  marginTop: "9px",
                  marginRight: "9px",
                }}
                to={"query"}
              >
                Query
              </Link>
            </Nav>

            <Nav>
              {userData ? (
                <>
                  <NavDropdown
                    align="end"
                    title={userId ? userData?.firstName : "Login"}
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item>
                      {" "}
                      <Link className="text-decoration-none" to="/">
                        Setting
                      </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={UserHandelDelete}>
                      {" "}
                      <Link className="text-decoration-none" to="/">
                        Log out
                      </Link>
                    </NavDropdown.Item>
                  </NavDropdown>
                </>
              ) : (
                <Link
                  style={{
                    textDecoration: "none",
                    color: "gray",
                    marginTop: "9px",
                    marginRight: "9px",
                  }}
                  to={"/login"}
                >
                  Login
                </Link>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
