import React, { useState, useEffect } from "react";
import { Container, Row, Button ,Table, Col,Modal,Form } from 'react-bootstrap';
import axios from "axios";
import { Link, useParams } from "react-router-dom"
import swal from 'sweetalert';
import Accordions from "./Accordion";
var _ = require('lodash');

export const AdminTrek = () => {

    const [data, setdata] = useState()
    const [overview , setOverview ] = useState()
    const [highlight , setHiglight ] = useState()
    const [itinerary , setItinerary ] = useState()
    const [transport , setTransport ] = useState()
    const [meals , setMeals ] = useState()
    const [permits , setPermits ] = useState()
    const [excluded , setExcluded ] = useState()
    const [thingsToCarry , setThingsToCarry ] = useState()
    const [cancelationPoilicy , setCancelationPoilicy ] = useState()
    const [termsCondition , setTermsCondition ] = useState()
    const [notes , setNotes ] = useState()
    const [accommodation , setAccommodation ] = useState()


    const [Altitude, setAltitude] = useState(`1200'ft`);
    const [TotalTrekking, setTotalTrekking] = useState("20 km");
    const [Region, setRegion] = useState("Garhwal");

    const [validated, setValidated] = useState(false);
    const [updatedId, setupdatedId] = useState()
    const [title, setTitle] = useState('')
    const [about, setAbout] = useState('')
    const [days, setDays] = useState('')
    const [level, setLevel] = useState('')
    const [fee, setFee] = useState('')
    const [image, setImage] = useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const id = window.location.href.split("/").pop()


    const getTrekData = () => {
        axios.post(`${process.env.REACT_APP_API}/trek/trekByCategory`, {category:id}).then(function (res) {
            for (let i = 0; i < res.data.length; i++) {
                res.data[i]['notes'] = (res.data[i].notes ? (Array.isArray(JSON.parse(res.data[i].notes)) ? JSON.parse(res.data[i].notes) : []) : []);
                res.data[i]['itinerary'] = (res.data[i].itinerary ? (Array.isArray(JSON.parse(res.data[i].itinerary)) ? JSON.parse(res.data[i].itinerary) : []) : []);
            }
            setdata(res.data);
        })
    }
    
    useEffect(() => {
        getTrekData()
    }, [id])
    

    const DeleteList = (id) => {
        axios.delete(`${process.env.REACT_APP_API}/trek/${id}`)
        .then(function(res){
            let newData = []
            setdata((prevState)=>{
                for (let i = 0; i < prevState.length; i++) {
                    if(prevState[i]._id!==id){
                        newData.push(prevState[i])
                    }
                }
                return [...newData];
            })
            swal({ title: "Delete", text: "Delete Successfully!!! ", icon: "success" });
        })
    }

    const UpdatelatestTrek = (el) => {

        setupdatedId(el._id)
        setTitle(el.title)
        setAbout(el.about)
        setDays(el.days)
        setLevel(el.level)
        setImage(el.image[0])
        setFee(el.fee)

        setOverview(el.overview)
        setHiglight(el.highlight)
        setItinerary(el.itinerary)
        setTransport(el.transport)
        setMeals(el.meals)
        setPermits(el.permits)
        setExcluded(el.excluded)
        setThingsToCarry(el.thingsToCarry)
        setCancelationPoilicy(el.cancelationPoilicy)
        setTermsCondition(el.termsCondition)
        setAccommodation(el.accommodation)
        setAltitude(el?.Altitude)
        setTotalTrekking(el?.TotalTrekking)
        setRegion(el?.Region)
        setNotes(el.notes)
        handleShow()
    }


    const HandelSubmit = (event) => {

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
    
        setValidated(true);

        event.preventDefault();
        var formData = new FormData();
        formData.append("title", title);
        formData.append("about", about);
        formData.append("days", days);
        formData.append("level", level);
        formData.append("fee", fee);
        formData.append("overview", overview);
        formData.append("highlight", highlight);
        formData.append("itinerary", JSON.stringify(itinerary));
        formData.append("transport", transport);
        formData.append("meals", meals);
        formData.append("permits", permits);
        formData.append("excluded", excluded);
        formData.append("thingsToCarry", thingsToCarry);
        formData.append("cancelationPoilicy", cancelationPoilicy);
        formData.append("termsCondition", termsCondition);
        formData.append("notes", JSON.stringify(notes));
        formData.append("accommodation", accommodation);
        formData.append("Altitude", Altitude);
        formData.append("TotalTrekking", TotalTrekking);
        formData.append("Region", Region);
        
        for (let index=0; index<image.length; index++) {
            formData.append("image", image[index]);
        }

        
        axios.patch(`${process.env.REACT_APP_API}/trek/${updatedId}`, formData, {
           
            headers: {
                "content-type": "multipart/formdata"
            }
        }).then(res=>{
            res.data.notes = (res.data.notes ? (Array.isArray(JSON.parse(res.data.notes)) ? JSON.parse(res.data.notes) : []) : []);
            res.data.itinerary = (res.data.itinerary ? (Array.isArray(JSON.parse(res.data.itinerary)) ? JSON.parse(res.data.itinerary) : []) : []);
            setdata((prevState)=>{
                for (let i = 0; i < prevState.length; i++) {
                    if(prevState[i]._id===updatedId){
                        
                        prevState[i] = res.data
                    }
                }
                return [...prevState];
            })
            swal({ title: "Updated", text: "Trek Updated Successfully!!! ", icon: "success" });
            handleClose()
        })
    }

    return(
        <Container style={{ marginTop: '50px' }}>
            <Row>
                <Col style={{padding : "0px"}}>
                    <div className="d-flex" style={{justifyContent: "space-between"}}>
                        <h1 style={{ fontFamily: 'DM Sans', fontSize: 20, textAlign: 'center', marginBottom: 20, fontWeight: 'bold' }} className="firstUperCase">All {id} Trek</h1>
                        <Link to={`/adminDashboard/add${id}`} className="btn btn-outline-success mb-2 firstUperCase">Add {id} Trek</Link>
                    </div>
                    <Table bordered style={{ fontFamily: 'DM Sans', fontSize: '12px' }} responsive>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Title </th>
                                <th>Level</th>
                                <th>About</th>
                                <th>Days</th>
                                <th>Trek Fee</th>
                                <th>Update</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.map((el , indx) => (
                                    <tr key={indx}>
                                        <td>{indx++ +1}</td>
                                        <td>{el?.title}</td>
                                        <td>{el?.level}</td>
                                        <td>{el?.about}</td>
                                        <td>{el?.days}</td>
                                        <td>{el?.fee}</td>

                                        <td><Button onClick={() => UpdatelatestTrek(el)} variant="outline-success" >edit</Button></td>
                                        <td><Button onClick={() => DeleteList(el._id) } variant="outline-danger" >delete</Button></td>
                                    </tr>                
                                ))
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={HandelSubmit} >
                    <Row className="mb-3">
                            <Form.Group  as={Col} md="4">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Title </Form.Label>
                                <Form.Control required type="text" placeholder="Title " style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setTitle(e.target.value)} value={title} />
                            </Form.Group>

                            <Form.Group as={Col} md="4">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Complete Day</Form.Label>
                                <Form.Control required type="text" placeholder="Days" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setDays(e.target.value)} value={days} />
                            </Form.Group>

                            <Form.Group as={Col} md="4">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Trek fee</Form.Label>
                                <Form.Control required type="text" placeholder="Trek Fee" style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setFee(e.target.value)} value={fee} />
                            </Form.Group>
                        </Row>


                        <Row className="mb-3">
              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Region{" "}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Region "
                  style={{ fontFamily: "DM sans", fontSize: "12px" }}
                  onChange={(e) => setRegion(e.target.value)}
                  value={Region}
                />
              </Form.Group>

              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Total Trekking
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Total Treking"
                  style={{ fontFamily: "DM sans", fontSize: "12px" }}
                  onChange={(e) => setTotalTrekking(e.target.value)}
                  value={TotalTrekking}
                />
              </Form.Group>

              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Altitude
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Altitude"
                  style={{ fontFamily: "DM sans", fontSize: "12px" }}
                  onChange={(e) => setAltitude(e.target.value)}
                  value={Altitude}
                />
              </Form.Group>
            </Row>


                        <Row className="mb-3">

                            <Form.Group as={Col} md="4">
                                <Form.Label>Level</Form.Label>
                                <Form.Select
                                    as={Col} md="4"
                                    onChange={(e) => setLevel(e.target.value)}
                                    value={level}
                                >
                                    <option>Select level</option>
                                    <option value="Easy">Easy</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Hard">Hard</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group  as={Col} md="4">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Permits </Form.Label>
                                <Form.Control required type="text" placeholder="Permits " style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setPermits(e.target.value)} value={permits} />
                            </Form.Group>
                           

                        

                            <Form.Group  as={Col} md="4">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Transport </Form.Label>
                                <Form.Control required type="text" placeholder="Transport " style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => setTransport(e.target.value)} value={transport} />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">

                            <Form.Group >
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Higlight </Form.Label>
                                <Form.Control required type="text" placeholder="Higlight"  as="textarea" style={{ fontFamily: 'DM sans', fontSize: '12px' , height:"100px" }} onChange={e => setHiglight(e.target.value)} value={highlight} />
                            </Form.Group>
                            

                             <Form.Group >
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Meals </Form.Label>
                                <Form.Control required type="text" as="textarea" placeholder="Meals " style={{ fontFamily: 'DM sans', fontSize: '12px' , height:"100px" }} onChange={e => setMeals(e.target.value)} value={meals} />
                            </Form.Group>

                            {/* <Form.Group>
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>About Trek</Form.Label>
                                <Form.Control required as="textarea" type="text" placeholder="About trek" style={{ fontFamily: 'DM sans', fontSize: '12px' , height:"100px" }} onChange={e => setAbout(e.target.value)} value={about} />
                            </Form.Group> */}

                            <Form.Group >
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>ThingsToCarry </Form.Label>
                                <Form.Control required type="text" as="textarea" placeholder="ThingsToCarry " style={{ fontFamily: 'DM sans', fontSize: '12px' , height:"100px" }} onChange={e => setThingsToCarry(e.target.value)} value={thingsToCarry} />
                            </Form.Group>
                        </Row>

                        <Row className="mb-3">

                            <Form.Group >
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>CancelationPoilicy </Form.Label>
                                <Form.Control required type="text" as="textarea" placeholder="CancelationPoilicy " style={{ fontFamily: 'DM sans', fontSize: '12px' , height:"100px" }} onChange={e => setCancelationPoilicy(e.target.value)} value={cancelationPoilicy} />
                            </Form.Group>

                            <Form.Group >
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>TermsCondition </Form.Label>
                                <Form.Control required type="text" as="textarea" placeholder="TermsCondition " style={{ fontFamily: 'DM sans', fontSize: '12px' , height:"100px" }} onChange={e => setTermsCondition(e.target.value)} value={termsCondition} />
                            </Form.Group>


                            <Form.Group >
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Overview </Form.Label>
                                <Form.Control required type="text" as="textarea" placeholder="Overview" style={{ fontFamily: 'DM sans', fontSize: '12px' ,height:"100px" }} onChange={e => setOverview(e.target.value)} value={overview} />
                            </Form.Group>
                            <Form.Group     >
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Accommodation </Form.Label>
                                <Form.Control required type="text" as="textarea"  placeholder="Excluded " style={{ fontFamily: 'DM sans', fontSize: '12px' ,height:"100px" }} onChange={e => setAccommodation(e.target.value)} value={accommodation} />
                            </Form.Group> 

                        </Row>

                            

                       
                        <Row className="mb-3">  


                            <Form.Group  as={Col} md="3">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Excluded </Form.Label>
                                <Form.Control required type="text" as="textarea" placeholder="Excluded " style={{ fontFamily: 'DM sans', fontSize: '12px',height:"100px" }} onChange={e => setExcluded(e.target.value)} value={excluded} />
                            </Form.Group> 
                         

                            <Form.Group as={Col} md="3">
                                <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Upload Image</Form.Label>
                                <Form.Control required type="file" multiple={true} placeholder="Upload Image" style={{ fontFamily: 'DM sans', fontSize: '12px' }} name="image" onChange={e => setImage(e.target.files)} />
                            </Form.Group>


                                <Form.Group>
                                    {/* <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Itinerary </Form.Label> */}
                                    {/* <Form.Control required type="text" placeholder="Itinerary " style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => {
                                        setItinerary(e.target.value)
                                    }} value={itinerary} /> */}

                                    <div className="accordion" >
                                        <Accordions title={"Itinerary"} item={itinerary} setItem={setItinerary} />
                                    </div>

                                </Form.Group>

                                <Form.Group>
                                    {/* <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Notes </Form.Label>
                                    <Form.Control required type="text" as="textarea" placeholder="Notes" style={{ fontFamily: 'DM sans', fontSize: '12px' , height:"100px" }} onChange={e => setNotes(e.target.value)} value={notes} /> */}

                                    <div className="accordion" >
                                        <Accordions title={"Notes"} item={notes} setItem={setNotes} />
                                    </div>
                                </Form.Group>

                        </Row>
                        <Button type="submit">Update</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>
    )
}