import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Button,
  Table,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import swal from "sweetalert";

export const Blog = () => {
  const [data, setdata] = useState();
  const [deleteId, setdeleteId] = useState();
  const [url , setUrl] = useState("")
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getdata();
  }, [deleteId]);

  const getdata = () => {
    axios.get(`${process.env.REACT_APP_API}/blog`).then(function (res) {
      setdata(res.data);
    });
  };

  const DeleteList = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API}/blog/${id}`)
      .then(function (res) {
        swal({
          title: "Delete",
          text: "Delete Successfully!!! ",
          icon: "success",
        });
        getdata();
      });
      getdata()
  };

  const HandelSubmit = async (event) => {
    event.preventDefault();

    let data = {
      blogUrl : url
    }

  
    axios
      .post(`${process.env.REACT_APP_API}/blog`, data)
      .then((res) => {
        getdata();
        swal({
          title: "Add",
          text: "Trek added Successfully!!! ",
          icon: "success",
        });
        setUrl("")
        handleClose();
      });
  };


  return (
    <Container style={{ marginTop: "50px" }}>
      <Row>
        <Col style={{ padding: "0px" }}>

        <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <h1
              style={{
                fontFamily: "DM Sans",
                fontSize: 20,
                textAlign: "center",
                marginBottom: 20,
                fontWeight: "bold",
              }}
            >
              Blog{" "}
            </h1>
            <Button
              onClick={handleShow}
              variant="outline-success"
              className=" mb-2 firstUperCase"
            >
              Add blog
            </Button>
          </div>
         

          <Table
            bordered
            style={{ fontFamily: "DM Sans", fontSize: "12px" }}
            responsive
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>Blog url </th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((el, indx) => (
                <tr key={indx}>
                  <td>{indx++ + 1}</td>
                  <td>{el.blogUrl}</td>
                  <td>
                    <Button
                      onClick={() => DeleteList(el._id)}
                      variant="outline-danger"
                    >
                      delete
                    </Button>
                  </td>

                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add About</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Blog Url </Form.Label>
              <Form.Control
                type="text"
                placeholder="xyz"
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={HandelSubmit}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
