import React, { useState } from "react";
import {
  Container,
  Row,
  Button,
  Table,
  Col,
  Modal,
  Form,
  Accordion,
} from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

const Accordions = ({ title, item, setItem, update }) => {
  const [edit, setEdit] = useState(false);

  const addItem = (e) => {
    e.preventDefault();
    setItem((prevState) => {
      return [{ _id: uuidv4(), title: "", content: [] }, ...prevState];
    });
  };

  const addSubItem = (index) => {
    setItem((prevState) => {
      prevState[index]["content"] = [
        { _id: uuidv4(), title: "" },
        ...prevState[index]["content"],
      ];

      // let jsonObject = prevState[index]['content'].map(JSON.stringify);
      // let uniqueSet = new Set(jsonObject);
      // prevState[index]['content'] = Array.from(uniqueSet).map(JSON.parse);

      prevState[index]["content"] = [
        ...new Map(
          prevState[index]["content"].map((item) => [item["title"], item])
        ).values(),
      ];

      return [...prevState];
    });
  };

  const onChange = (index, e) => {
    e.stopPropagation();
    setItem((prevState) => {
      prevState[index]["title"] = e.target.value;
      return [...prevState];
    });
  };

  const onSubChange = (index, subIndex, e) => {
    e.stopPropagation();
    setItem((prevState) => {
      prevState[index]["content"][subIndex]["title"] = e.target.value;
      return [...prevState];
    });
  };

  const onBlur = (e) => {
    e.stopPropagation();
  };

  const onSubBlur = (e) => {
    e.stopPropagation();
  };

  const onDelete = (id) => {
    setItem((prevState) => {
      let newArr = [];
      for (let i = 0; i < prevState.length; i++) {
        if (prevState[i]?._id !== id) {
          newArr.push(prevState[i]);
        }
      }
      prevState = newArr;
      return [...prevState];
    });
  };

  const onSubDelete = (index, id) => {
    setItem((prevState) => {
      if (prevState[index] && prevState[index]["content"]) {
        let newArr = [];
        for (let i = 0; i < prevState[index]["content"].length; i++) {
          if (prevState[index]["content"][i]?._id !== id) {
            newArr.push(prevState[index]["content"][i]);
          }
        }
        prevState[index]["content"] = [...newArr];
      }

      return [...prevState];
    });
  };

  return (
    <>
      <Form.Label
        style={{ fontFamily: "DM sans", fontWeight: "bold", fontSize: "15px" }}
      >
        {title}{" "}
        <button className="btn btn-outline-success" onClick={(e) => addItem(e)}>
          +
        </button>{" "}
      </Form.Label>
      <Accordion>
        {item?.map((item, key) => {
          return (
            <Accordion.Item eventKey={key}>
              <Accordion.Header>
                <div id="header_div_add">
                  <input
                    type={"text"}
                    value={item?.title}
                    onChange={(e) => onChange(key, e)}
                    style={{ width: "80%", padding: "2px" }}
                    onBlur={onBlur}
                  />
                  <div>
                    <button
                      type="button"
                      className="btn btn-outline-success mb-2"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        addSubItem(key);
                      }}
                    >
                      +
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-danger mb-2"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onDelete(item._id);
                      }}
                    >
                      delete
                    </button>
                  </div>
                </div>
              </Accordion.Header>

              {item?.content?.length > 0 && (
                <Accordion.Body>
                  {item?.content?.map((content, key1) => {
                    return (
                      <Accordion
                        defaultActiveKey={`${key}${key1}`}
                        className="notDropdown"
                      >
                        <Accordion.Item eventKey={`${key}${key1}`}>
                          <Accordion.Header>
                            <div id="header_div_add">
                              <input
                                type={"text"}
                                value={content?.title}
                                onChange={(e) => onSubChange(key, key1, e)}
                                style={{ width: "80%", padding: "2px" }}
                                onBlur={onSubBlur}
                              />
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-outline-danger mb-2"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onSubDelete(key, content._id);
                                  }}
                                >
                                  delete
                                </button>
                              </div>
                            </div>
                          </Accordion.Header>
                        </Accordion.Item>
                      </Accordion>
                    );
                  })}
                </Accordion.Body>
              )}
            </Accordion.Item>
          );
        })}
      </Accordion>
    </>
  );
};

export default Accordions;
