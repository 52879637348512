import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Modal, Row, Card, Form, Button } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import swal from "sweetalert";
import Slider from "react-slick";

export const Review = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [FullName, setFullName] = useState("");
  const [Email, setEmail] = useState("");
  const [ReviewText, setReviewText] = useState("");
  const [Ratting, setRatting] = useState();
  const [validated, setValidated] = useState(false);
  const [reviewData, setReviewData] = useState();

  useEffect(() => {
    getdata();
  }, []);

  const getdata = () => {
    axios.get(`${process.env.REACT_APP_API}/review`).then(function (res) {
      setReviewData(res.data);
    });
  };

  const ratingChanged = (newRating) => {
    setRatting(newRating);
  };

  const data = {
    FullName,
    Email,
    ReviewText,
    Ratting,
  };

  const HandelSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      setValidated(true);
      event.stopPropagation();
    } else {
      axios.post(`${process.env.REACT_APP_API}/review`, data);
      swal({ title: "Send", text: "send Successfully!!! ", icon: "success" });
      handleClose();
    }
    setFullName(" ");
    setEmail(" ");
    setReviewText(" ");
    setRatting(" ");
  };

  console.log(data);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Container
        className="px-5 py-3"
        fluid
        style={{ backgroundColor: "#efeeea" }}
      >
        <span style={{ display: "flex", justifyContent: "space-between" }}>
          <h1 style={{ fontSize: "30px" }}>Review</h1>
          <button className="Review-Btn" onClick={handleShow}>
            Rate Us
          </button>
        </span>

        <hr />
        <Row>
          <Slider {...settings}>
            {reviewData?.map((el, idx) => {
              return (
                <Row>
                  <div>
                    <Card style={{ width: "18rem", height: "25rem" }}>
                      <Card.Img
                        style={{ height: "190px" }}
                        variant="top"
                        src={
                          "https://www.sailab.fi/wp-content/uploads/2017/10/dummy-profile-image-male.jpg"
                        }
                      />
                      <Card.Body style={{ paddingTop: "0px" }}>
                        <span>
                          <ReactStars
                            size={35}
                            activeColor="#ffd700"
                            value={el.Ratting}
                            edit={false}
                          />
                        </span>
                        <Card.Title>{el.FullName}</Card.Title>
                        <Card.Text>{el.ReviewText}</Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </Row>
              );
            })}
          </Slider>
        </Row>
      </Container>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Rate Your Experience</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={HandelSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                required
                type="text"
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Enter Name"
                rows={3}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                required
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={Email}
                placeholder="name@example.com"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Example textarea</Form.Label>
              <Form.Control
                required
                as="textarea"
                onChange={(e) => setReviewText(e.target.value)}
                rows={3}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Ratting</Form.Label>
              <ReactStars
                required
                count={5}
                onChange={ratingChanged}
                size={35}
                activeColor="#ffd700"
                value={Ratting}
              />
            </Form.Group>
            <button
              type="submit"
              className="Review-Btn"
              style={{ width: "100%", height: "45px" }}
            >
              Submit
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
