import React, { useState, useEffect } from "react";
import { Container, Row, Card, Button, Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { getImageURL } from "../commonJs";

export const CharDham = () => {
  const [data, setdata] = useState();
  useEffect(() => {
    getdata();
  }, []);

  const getdata = () => {
    axios
      .post(`${process.env.REACT_APP_API}/trek/trekByCategory`, {
        category: "chardham",
      })
      .then(function (res) {
        setdata(res.data);
      });
  };

  var settings = {
    dots: true,
    infinite: false,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Container fluid style={{ backgroundColor: "#efeeea" }}>
      <div className="px-5 py-3">
        <h3>Char Dham Yatra</h3>
        <hr />
        <Slider {...settings}>
          {data?.map((el, index) => {
            return (
              <div>
                <div className="row">
                  <Col lg={6} sm={12}>
                    <h2 style={{ color: "#fb8500" }}>{el.title}</h2>
                    <p className="mx-aut0 d-block" style={{ fontSize: "20px" }}>
                      {el.overview}
                    </p>
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: "13px",
                        color: "gray",
                      }}
                    >
                      <p>Difficulty Level | {el.level}</p>{" "}
                      <p>Duration | {el.days}</p>
                    </span>

                    <b>
                      {" "}
                      <p style={{ color: "black" }}>₹{el.fee}</p>
                    </b>

                    <Link to={`/latestTrek/${el._id}`}>
                      <Button style={{ width: "100%" ,marginBottom :"20px" }} variant="primary">
                        View Details
                      </Button>
                    </Link>
                  </Col>
                  <Col lg={6} sm={12} >
                    <img
                      style={{height :"100%" , width : "100%"}}
                      src={getImageURL(el.image[0])  }
                      alt="not found"
                      width={"100%"}
                      height={"100%"}
                      className="mx-auto d-block"
                    />
                  </Col>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </Container>
  );
};
