import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";

import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <Container fluid style={{ background: "#31353d" }}>
      <Row style={{ margin: "0px" }}>
        <Col className="Footer_thought" md={5}>
          <img
            style={{ marginTop: "40px" }}
            src="/kumgharHikes.jpeg"
            alt=""
            width={"20%"}
          />
          <p style={{ color: "white" }}>
            Trekking means a travelling experience with <br /> a thrilling
            excitement.
          </p>
        </Col>
        <Col md={4}>
          <h1 style={{ color: "white", marginTop: "40px" }}> Talk to us </h1>
          <ul style={{ marginLeft: "", color: "white" }}>
            <li>
              <a
                style={{ textDecoration: "none", color: "white" }}
                href="https://kumgarhhikes.com"
              >
                kumgarhhikes.com
              </a>
            </li>

            <li>
              {" "}
              <a
                style={{ textDecoration: "none", color: "white" }}
                href="tel:8595204349"
              >
                859-520-4349
              </a>{" "}
            </li>

            <li>
              {" "}
              <a
                style={{ textDecoration: "none", color: "white" }}
                href="tel:8588036224"
              >
                858-803-6224
              </a>{" "}
            </li>
          </ul>
        </Col>

        <Col>
          <h1 style={{ color: "white", marginTop: "40px", fontSize: "30px" }}>
            {" "}
            Join us{" "}
          </h1>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <div style={{ color: "white" }}>
                <a
                  href="http://wa.me/+918595204349"
                  target="_blank"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <WhatsAppIcon /> WhatsApp
                </a>
              </div>
              <div style={{ color: "white" }}>
                <a
                  href="https://www.youtube.com/@UttrakhandHikes"
                  target="_blank"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <YouTubeIcon /> YouTube
                </a>
              </div>
            </div>

            <div style={{ marginRight: "30px" }}>
              <div style={{ color: "white" }}>
                <a
                  href="https://instagram.com/kumgarh_hikes?igshid=ZDdkNTZiNTM="
                  target="_blank"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <InstagramIcon /> Instagram
                </a>
              </div>
              <div style={{ color: "white" }}>
                <a
                  href="https://www.facebook.com/profile.php?id=100088568179499"
                  target="_blank"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <FacebookIcon /> Facebook
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <h2 style={{ textAlign: "center", fontSize: "10px", color: "white" }}>
          {" "}
          Copyright © kumgarhhikes.com 2014 - 2021 - All Rights Reserved.{" "}
        </h2>
      </Row>
    </Container>
  );
}
