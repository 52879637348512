import React, { useState } from "react";
import { Container, Row, Modal, Form, Col, Button } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  FcGlobe,
  FcSportsMode,
  FcPicture,
  FcPositiveDynamic,
  FcCalendar,
} from "react-icons/fc";
import "../Style/detail.css";
import {
  FaBed,
  FaRegAddressCard,
  FaTruckMonster,
  FaUtensils,
} from "react-icons/fa";
import swal from "sweetalert";
import { getImageURL } from "../commonJs";

export const Detail = () => {
  const [validated, setValidated] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [fullName, setfullname] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [Query, setQuery] = useState("");
  const [querydata, setquerydata] = useState();

  const Contactdata = {
    fullName: fullName,
    contact: contact,
    email: email,
    query: Query,
  };

  const HandelSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      setValidated(true);
      event.stopPropagation();
    } else {
      axios
        .post(`${process.env.REACT_APP_API}/query`, Contactdata)
        .then(function (res) {
          console.log(res.data);
          setquerydata(res.data);
        });
      swal({ title: "Send", text: "send Successfully!!! ", icon: "success" });
      handleClose();
    }
    setfullname("");
    setContact("");
    setEmail("");
    setQuery("");
  };

  React.useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/trek/${id}`).then(function (res) {
      setData(res.data);
      console.log(res);
    });
  }, []);

  return (
    <Container fluid style={{ marginBottom: "100px" }}>
      <Row style={{ height: "400px" }}>
        <span
          style={{ position: "absolute", fontWeight: 600, fontSize: "28px" }}
        >
          {data?.title}
        </span>
        <img
          style={{ height: "400px", padding: "0px" }}
          src={  getImageURL(data?.image[0])}
          alt={data?.title}
          width={"100%"}
          height={"100%"}
        />
      </Row>

      <Row style={{ paddingLeft: "40px", paddingRight: "40px" }}>
        {/* ------------------------------------------------------overview ------------------------------------------------------------------------*/}
        <Col md={8} style={{ marginTop: "50px" }}>
          <h2 style={{ color: "#fb8500" }}>Overview</h2>
          <div className="main_div p-3">
            <div className="textareas">
              <p>{data?.overview}</p>
              <Row style={{ display: "flex" }}>
                <Col>
                  <h5>
                    <FcGlobe /> Region
                  </h5>
                  <p>{data?.Region}</p>
                </Col>
                <Col>
                  <h5>
                    <FcSportsMode /> Total Trekking
                  </h5>
                  <p>{data?.TotalTrekking}</p>
                </Col>
                <Col>
                  <h5>
                    {" "}
                    <FcPositiveDynamic /> Grade
                  </h5>
                  <p>{data?.level}</p>
                </Col>
                <Col>
                  <h5>
                    <FcPicture /> Altitude
                  </h5>
                  <p>{data?.Altitude}</p>
                </Col>
                <Col>
                  <h5>
                    {" "}
                    <FcCalendar /> Days/Night
                  </h5>
                  <p>{data?.days}</p>
                </Col>
              </Row>
            </div>
          </div>
          <br />
          <br />

          {data?.highlight !== "undefined" && (
            <>
              <h2 style={{ color: "#fb8500" }}>Higlight</h2>
              <div className="main_div">
                <div className="textareas">
                  <ul>
                    {data?.highlight.split("\r\n").map((highlight) => (
                      <li> {highlight} </li>
                    ))}
                  </ul>
                </div>
              </div>
              <br />
              <br />
            </>
          )}

          {/* /* ------------------------------------------------------Itineary ------------------------------------------------------------------------*/}

          <h2 style={{ color: "#fb8500" }}>Itineary</h2>
          <div className="main_div">
            <div className="textareas">
              {data?.itinerary &&
                JSON.parse(data?.itinerary).map((itinerary) => (
                  <li className="bulletNon">
                    <p className="sub_header"> {itinerary.title}</p>
                    <ul>
                      {itinerary?.content?.map((subItinerary) => (
                        <li> {subItinerary.title} </li>
                      ))}
                    </ul>
                  </li>
                ))}
            </div>
          </div>
          <br />
          <br />

          {/* /* ------------------------------------------------------cancelation-Poilicy ------------------------------------------------------------------------*/}

          {data?.cancelationPoilicy !== "undefined" && (
            <>
              <h2 style={{ color: "#fb8500" }}>Cancelation Poilicy</h2>
              <div className="main_div">
                <div className="textareas">
                  <ul>
                    {data?.cancelationPoilicy
                      .split("\r\n")
                      .map((cancelationPoilicy) => (
                        <li> {cancelationPoilicy} </li>
                      ))}
                  </ul>
                </div>
              </div>
            </>
          )}
          <br />
          <br />

          {/* /* ------------------------------------------------------Things-TO-Carry ------------------------------------------------------------------------*/}

          {data?.thingsToCarry !== "undefined" && (
            <>
              <h2 style={{ color: "#fb8500" }}>Things To Carry</h2>
              <div className="main_div">
                <div className="textareas">
                  <ul>
                    {data?.thingsToCarry.split("\r\n").map((thingsToCarry) => (
                      <li> {thingsToCarry} </li>
                    ))}
                  </ul>
                </div>
              </div>
            </>
          )}
        </Col>

        <Col style={{ marginTop: "100px", marginLeft: "10px" }}>
          <Row className="row_div">
            {/* /* ------------------------------------------------------Trek Fee ------------------------------------------------------------------------*/}

            <h4 style={{ color: "#fb8500" }}>Trek Fee</h4>
            <p> {data?.fee} </p>

            <Button style={{ marginBottom: "2 0px" }}>Book Now</Button>
            <hr />

            {/* /* ------------------------------------------------------Trek Inclusions ------------------------------------------------------------------------*/}

            <h4 style={{ color: "#fb8500" }}>Trek Inclusions</h4>
            <p className="notes_lab">
              {" "}
              <FaTruckMonster /> Transport to and from the base camp
            </p>
            <p> {data?.transport} </p>

            <p className="notes_lab">
              {" "}
              <FaBed /> Accommodation
            </p>
            <p> {data?.accommodation} </p>

            <p className="notes_lab">
              {" "}
              <FaUtensils /> Meals
            </p>
            <p>{data?.meals}</p>

            <p className="notes_lab">
              {" "}
              <FaRegAddressCard /> Permits/ Forest fees
            </p>
            <p>{data?.permits}</p>

            <hr />
            <br />
            {/* /* ------------------------------------------------------Trek notes ------------------------------------------------------------------------*/}

            <h4 style={{ color: "#fb8500" }}>Trek Notes</h4>
            {/* {data?.notes.split("\r\n").map(notes =>  <li>  ◉ {notes} </li>) } */}
            <ul>
              {data?.notes &&
                JSON.parse(data?.notes).map((itinerary) => (
                  <li className="bulletNon">
                    <span className="notes_lab">{itinerary.title}</span>
                    <ul>
                      {itinerary?.content?.map((subItinerary) => (
                        <li> {subItinerary.title} </li>
                      ))}
                    </ul>
                  </li>
                ))}
            </ul>
            <br />
            <br />

            <p className="notes_lab">
              {" "}
              People with below conditions are not allowed:
            </p>
            <div class="d-block p-2 notes_lab">
              <strong style={{ color: "red" }}>Please note: </strong>
              <span style={{ color: "black" }}>
                If you had any medical history, please let us know.
              </span>
            </div>
            <hr />
            <br />

            {/* /* ------------------------------------------------------Short-Itineary ------------------------------------------------------------------------*/}

            <h4 style={{ color: "#fb8500" }}>Itineary</h4>
            {data?.itinerary &&
              JSON.parse(data?.itinerary).map((itinerary) => (
                <li className="bulletNon">
                  <p> {itinerary.title}</p>
                </li>
              ))}
            <br />
            <br />
            <hr />

            {/* /* ------------------------------------------------------More-Relative-Image ------------------------------------------------------------------------*/}

            <h4 style={{ color: "#fb8500" }}>Photos {data?.title}</h4>
            {data?.image.map((imageShow) => (
              <img
                style={{ width: "48%", padding: "2%" }}
                src={imageShow}
                alt=""
              />
            ))}
            <br />
            <br />
            <hr />
            {/* /* ------------------------------------------------------Trek termsCondition ------------------------------------------------------------------------*/}

            <h4 style={{ color: "#fb8500" }}>Trek Terms Condition</h4>
            <div>
              <ul>
                {data?.termsCondition.split("\r\n").map((termsCondition) => (
                  <li> {termsCondition} </li>
                ))}
              </ul>
            </div>
            <br />
            <br />

            <div class="d-block p-2 notes_lab">
              <strong style={{ color: "red" }}>Please note: </strong>
              <span style={{ color: "black" }}>
                If you are not follow the above conditons we are not allow !!
              </span>
            </div>
          </Row>
        </Col>
      </Row>

      {/* /* ------------------------------------------------------Trek Query ------------------------------------------------------------------------*/}

      <div id="sendquery">
        <button type="button" class="btn btn-info btn-md" onClick={handleShow}>
          Send Query
        </button>
      </div>

      <Modal show={show} onHide={handleClose} centered size="md">
        <Modal.Body>
          <Modal.Header
            style={{ padding: "0px", paddingBottom: "20px" }}
            closeButton
          >
            <Modal.Title>Query</Modal.Title>
          </Modal.Header>

          <Form
            className="mt-2"
            noValidate
            validated={validated}
            onSubmit={HandelSubmit}
          >
            <Form.Group className="mx-auto d-block" as={Col} md="11">
              <Form.Label style={{ fontFamily: "DM Sans", fontSize: "12px" }}>
                Full Name
              </Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Full Name"
                style={{ fontFamily: "DM Sans", fontSize: "12px" }}
                onChange={(e) => setfullname(e.target.value)}
                value={fullName}
              />
            </Form.Group>

            <Form.Group className="mx-auto d-block" as={Col} md="11">
              <Form.Label style={{ fontFamily: "DM Sans", fontSize: "12px" }}>
                Email
              </Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Email"
                style={{ fontFamily: "DM Sans", fontSize: "12px" }}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </Form.Group>

            <Form.Group className="mx-auto d-block" as={Col} md="11">
              <Form.Label style={{ fontFamily: "DM Sans", fontSize: "12px" }}>
                Contact
              </Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Contact"
                style={{ fontFamily: "DM Sans", fontSize: "12px" }}
                onChange={(e) => setContact(e.target.value)}
                value={contact}
              />
            </Form.Group>

            <Form.Group className="mx-auto d-block" as={Col} md="11">
              <Form.Label style={{ fontFamily: "DM Sans", fontSize: "12px" }}>
                Query
              </Form.Label>
              <Form.Control
                required
                as="textarea"
                placeholder="Query"
                rows={1}
                style={{
                  fontFamily: "DM Sans",
                  fontSize: "12px",
                  height: "90px",
                }}
                onChange={(e) => setQuery(e.target.value)}
                value={Query}
              />
            </Form.Group>
            <br></br>

            <Button
              style={{ width: "92%", marginBottom: "20px" }}
              className="mx-auto d-block"
              type="submit"
            >
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
