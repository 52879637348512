import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import swal from "sweetalert";
import Accordions from "./Accordion";

export const AddTrek = () => {
  const [updatedId, setUpdatedId] = useState(null);
  const [validated, setvalidated] = useState(false);

  const [title, setTitle] = useState("");

  const [about, setAbout] = useState("");
  const [overview, setOverview] = useState(
    "Chopta  Tungnath trek is short and easy in the Chopta Tungnath trek which runs parallel to the sky-scraping Himalayan Treks. Tunganath is the highest temple of Uttarakhand. During this hiking, one can enjoy clear view of majestic peaks of Panchchuli, Nanda Devi, Nilkanth and Kedarnath. Chopta gives a picturesque view of the Himalayas. In the early spring the Rhododendron forest glow red as the flowers bloom."
  );

  const [highlight, setHiglight] =
    useState(`You will be picked from Rishikesh at 6:30 am in a Tata Sumo or a similar vehicle.
Expected arrival time at Joshimath – 5:00 pm (evening tea, briefing of the trek and dinner)
Rishikesh to Devprayag (confluence of Bhagirathi river and Alaknanda river)
Accommodation in guest house. Relax for the night.`);
  const [itinerary, setItinerary] = useState([
    {
      _id: "36f7080f-cb37-40ca-b998-7714fbbf3286",
      title: "Day 4 - Hargaon – Sankri",
      content: [
        {
          _id: "111bee7b-98ff-4f7f-a08f-95ac9cbb2f39",
          title: "On arrival in Sankri Drive back to Dehradun. Trip ends here.",
        },
        {
          _id: "e075d80d-969e-4178-99c1-084c7620ba07",
          title: "After Breakfast trek back to Sankri village.",
        },
      ],
    },
    {
      _id: "fa337388-9f3e-4935-8fba-476df78b0d23",
      title: "Day 3 - Juda ka Talab - Kedarkantha Base (4 hrs)",
      content: [
        {
          _id: "4db024f3-dfb9-43cf-9576-2b0469c3e3bf",
          title: "Overnight will be spent in tents.",
        },
        {
          _id: "fdd5a46c-2cb3-4f9f-b59a-0471b40aa29c",
          title:
            "Witness the views of Swargarohini, Bandarpoonch and Kalanag peaks",
        },
        {
          _id: "155d9a82-56f7-4894-b2a0-58688a9ec3c1",
          title:
            "Reach the destination and revel in the open snow ground ringed by trees.",
        },
        {
          _id: "3baaf097-8471-4c8e-8bbd-cd1204c7f47a",
          title: "Trek to KedarKantha Base camp, after breakfast.",
        },
        {
          _id: "96a782e0-22f0-45bf-87e4-1a94bb7a07b9",
          title:
            "Can witness awesome views of snow clapped peaks in early morning",
        },
      ],
    },
    {
      _id: "317ac0ab-fdda-477a-acd6-ad83ff9b7028",
      title: "Day 2 - Sankri - Juda-ka-Talab - Shepherd Camp (3-4 hrs)",
      content: [
        {
          _id: "199cecaf-8d1b-4893-8fbc-309eef598e03",
          title: "Tents will be pitched here and overnight stay will be here",
        },
        {
          _id: "5817783b-c6d2-49e5-89d8-1bfe07cc0ed6",
          title: "It will take 2-2.5 hours to reach Juda-ka-Talab",
        },
        {
          _id: "38d99171-2099-4036-8b08-b3813f5eb9b6",
          title:
            "The trail is of moderate nature and passes through a lovely forest for most of the part",
        },
        {
          _id: "9fd4fd62-b2da-4081-9c36-f9dfa40911a5",
          title: "Trek to Juda ka Talab, after breakfast",
        },
      ],
    },
    {
      _id: "b0b0077d-b7d4-4042-8714-f420aa8f4abb",
      title: "Day 1 - Dehradun - Sankri (7-8 hrs by road)",
      content: [
        {
          _id: "dbc9f48e-9229-477c-804d-8ddd6d60ba89",
          title: "Sankari village can be visited in evening",
        },
        {
          _id: "6c0673a6-8610-44ec-937f-89a9c936a928",
          title:
            "From Dehradun, drive to Sankri. Mussoorie, Kempty Fall, Nainbag, Purola and Mori will be on route.",
        },
        {
          _id: "b4118a73-5613-4298-b587-0ec2fbbc2c30",
          title: "Stay will in hotel",
        },
      ],
    },
  ]);
  const [transport, setTransport] = useState(
    "The above trek cost includes transport to and from base camp."
  );
  const [accommodation, setAccommodation] = useState(
    "Stay is included from Day 1 to Last Day. Tents/Homestays/lodges will be provided. Tripple sharing Accomodation and seprate accomodation for girls/ladies will be povided"
  );
  const [meals, setMeals] = useState(
    "All meals from dinner on Day 1 to breakfast at Last day are included. Veg food will be provided during the trek."
  );
  const [permits, setPermits] = useState(
    "UTT will arrange trekking permits and forest passes."
  );
  const [excluded, setExcluded] = useState(
    "Personal Expense of any kind. The trek cost does not include stay at any location on last day"
  );
  const [thingsToCarry, setThingsToCarry] =
    useState(`An identity card - Original and Photocopies (MANDATORY)
BackPack & Rain Cover (50-60 liter)
Day Pack & Rain Cover (20-30 liter, If you hire mule or porter)
1 ltr of pet water bottle or hydration pack.
Floaters or Sandals, Trekking Shoes (waterproof are better during snow and rain)`);
  const [cancelationPoilicy, setCancelationPoilicy] =
    useState(`Cancellation 30 days before the start date of the trek — Get a refund with 10% cancellation charges.
Cancellation between 29 days and 21 days before the start date of the trek — Get a refund with 50% cancellation charges.
Cancellation within 20 days prior to the start date of the trek, or no show on the start day of the trek — Unfortunately, no refund or voucher.
If you had used a voucher to register for the trek but now wish to cancel the trek, the above cancellation policy applies. If there is a balance in your voucher after cancellation charges, it stays credited to your account. If the validity of the voucher has expired at the time of cancellation, you do not get any voucher/refund or an extension for it.`);
  const [termsCondition, setTermsCondition] =
    useState(`These terms and conditions outline the rules and regulations for the use of Hike in India’s Website
Republish material from trek in India.
Sell, rent, or sub-license material from trek in India.
Reproduce, duplicate or copy material from trek in India.
Redistribute content from trek in India.`);
  const [notes, setNotes] = useState([
    {
      _id: "cd2c1faa-e118-43ba-b2c8-c25bc78c4969",
      title: "Chilren should meet below requirements",
      content: [
        {
          _id: "5001fbf4-115c-487f-b505-4bd3b0cb353c",
          title: "Below 8 years are not allowed",
        },
      ],
    },
  ]);

  const [days, setDays] = useState("1 day");
  const [level, setLevel] = useState("Easy");
  const [fee, setFee] = useState("199 Rs");
  const [image, setImage] = useState([]);

  const [Altitude, setAltitude] = useState(`1200'ft`);
  const [TotalTrekking, setTotalTrekking] = useState("20 km");
  const [Region, setRegion] = useState("Garhwal");

  const id = window.location.href.split("/").pop().replace("add", "");

  let formData = new FormData();
  formData.append("image", image);

  const handleSliderImages = (e) => {
    if (e.target.files) {
      setImage({ ...image, image: [...e.target.files] });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    var formData = new FormData();
    formData.append("category", id);
    formData.append("title", title);
    formData.append("about", about);
    formData.append("days", days);
    formData.append("level", level);
    formData.append("fee", fee);
    formData.append("overview", overview);
    formData.append("highlight", highlight);
    formData.append("itinerary", JSON.stringify(itinerary));
    formData.append("transport", transport);
    formData.append("meals", meals);
    formData.append("permits", permits);
    formData.append("excluded", excluded);
    formData.append("thingsToCarry", thingsToCarry);
    formData.append("cancelationPoilicy", cancelationPoilicy);
    formData.append("termsCondition", termsCondition);
    formData.append("notes", JSON.stringify(notes));
    formData.append("accommodation", accommodation);
    formData.append("Altitude", Altitude);
    formData.append("TotalTrekking", TotalTrekking);
    formData.append("Region", Region);

    for (let index = 0; index < image.length; index++) {
      formData.append("image", image[index]);
    }
    if (updatedId) {
      axios
        .patch(`${process.env.REACT_APP_API}/trek/${updatedId}`, formData, {
          headers: {
            "content-type": "multipart/formdata",
          },
        })
        .then((res) => {
          swal({
            title: "Updated",
            text: "Trek Updated Successfully!!! ",
            icon: "success",
          });
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API}/trek`, formData, {
          headers: {
            "content-type": "multipart/formdata",
          },
        })
        .then((res) => {
          setUpdatedId(res.data._id);
          swal({
            title: "Add",
            text: "Trek added Successfully!!! ",
            icon: "success",
          });
        });
    }
  };

  const updateTrek = () => {};

  return (
    <Container style={{ marginTop: "50px" }}>
      <Row>
        <Col>
          <h1
            className="firstUperCase"
            style={{
              fontFamily: "DM sans",
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "20px",
            }}
          >
            Add {id} Trek{" "}
          </h1>
          <Form
            style={{ marginTop: "50px" }}
            className="mx-auto d-block"
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Row className="mb-3">
              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Title{" "}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Title "
                  style={{ fontFamily: "DM sans", fontSize: "12px" }}
                  onChange={(e) => setTitle(e.target.value)}
                  value={title}
                />
              </Form.Group>

              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Complete Day
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Days"
                  style={{ fontFamily: "DM sans", fontSize: "12px" }}
                  onChange={(e) => setDays(e.target.value)}
                  value={days}
                />
              </Form.Group>

              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Trek fee
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Trek Fee"
                  style={{ fontFamily: "DM sans", fontSize: "12px" }}
                  onChange={(e) => setFee(e.target.value)}
                  value={fee}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Region{" "}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Title "
                  style={{ fontFamily: "DM sans", fontSize: "12px" }}
                  onChange={(e) => setRegion(e.target.value)}
                  value={Region}
                />
              </Form.Group>

              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Total Trekking
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Days"
                  style={{ fontFamily: "DM sans", fontSize: "12px" }}
                  onChange={(e) => setTotalTrekking(e.target.value)}
                  value={TotalTrekking}
                />
              </Form.Group>

              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Altitude
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Trek Fee"
                  style={{ fontFamily: "DM sans", fontSize: "12px" }}
                  onChange={(e) => setAltitude(e.target.value)}
                  value={Altitude}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="4">
                <Form.Label>Level</Form.Label>
                <Form.Select
                  as={Col}
                  md="4"
                  onChange={(e) => setLevel(e.target.value)}
                  value={level}
                >
                  <option>Select level</option>
                  <option value="Easy">Easy</option>
                  <option value="Medium">Medium</option>
                  <option value="Hard">Hard</option>
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Permits{" "}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Permits "
                  style={{ fontFamily: "DM sans", fontSize: "12px" }}
                  onChange={(e) => setPermits(e.target.value)}
                  value={permits}
                />
              </Form.Group>

              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Transport{" "}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Transport "
                  style={{ fontFamily: "DM sans", fontSize: "12px" }}
                  onChange={(e) => setTransport(e.target.value)}
                  value={transport}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Higlight{" "}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Higlight"
                  as="textarea"
                  style={{
                    fontFamily: "DM sans",
                    fontSize: "12px",
                    height: "100px",
                  }}
                  onChange={(e) => setHiglight(e.target.value)}
                  value={highlight}
                />
              </Form.Group>

              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Meals{" "}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  as="textarea"
                  placeholder="Meals "
                  style={{
                    fontFamily: "DM sans",
                    fontSize: "12px",
                    height: "100px",
                  }}
                  onChange={(e) => setMeals(e.target.value)}
                  value={meals}
                />
              </Form.Group>

              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  ThingsToCarry{" "}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  as="textarea"
                  placeholder="ThingsToCarry "
                  style={{
                    fontFamily: "DM sans",
                    fontSize: "12px",
                    height: "100px",
                  }}
                  onChange={(e) => setThingsToCarry(e.target.value)}
                  value={thingsToCarry}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  CancelationPoilicy{" "}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  as="textarea"
                  placeholder="CancelationPoilicy "
                  style={{
                    fontFamily: "DM sans",
                    fontSize: "12px",
                    height: "100px",
                  }}
                  onChange={(e) => setCancelationPoilicy(e.target.value)}
                  value={cancelationPoilicy}
                />
              </Form.Group>

              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  TermsCondition{" "}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  as="textarea"
                  placeholder="TermsCondition "
                  style={{
                    fontFamily: "DM sans",
                    fontSize: "12px",
                    height: "100px",
                  }}
                  onChange={(e) => setTermsCondition(e.target.value)}
                  value={termsCondition}
                />
              </Form.Group>

              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Overview{" "}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  as="textarea"
                  placeholder="Overview"
                  style={{
                    fontFamily: "DM sans",
                    fontSize: "12px",
                    height: "100px",
                  }}
                  onChange={(e) => setOverview(e.target.value)}
                  value={overview}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Accommodation{" "}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  as="textarea"
                  placeholder="Excluded "
                  style={{
                    fontFamily: "DM sans",
                    fontSize: "12px",
                    height: "100px",
                  }}
                  onChange={(e) => setAccommodation(e.target.value)}
                  value={accommodation}
                />
              </Form.Group>

              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Excluded{" "}
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  rows={5}
                  as="textarea"
                  placeholder="Excluded "
                  style={{ fontFamily: "DM sans", fontSize: "12px" }}
                  onChange={(e) => setExcluded(e.target.value)}
                  value={excluded}
                />
              </Form.Group>

              <Form.Group as={Col} md="4">
                <Form.Label
                  style={{
                    fontFamily: "DM sans",
                    fontWeight: "bold",
                    fontSize: "15px",
                  }}
                >
                  Upload Image
                </Form.Label>
                <Form.Control
                  required
                  multiple={true}
                  type="file"
                  placeholder="Uploads Image"
                  style={{ fontFamily: "DM sans", fontSize: "12px" }}
                  name="image"
                  onChange={(e) => setImage(e.target.files)}
                />
              </Form.Group>
            </Row>

            <Row>
              <Form.Group>
                {/* <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Itinerary </Form.Label> */}
                {/* <Form.Control required type="text" placeholder="Itinerary " style={{ fontFamily: 'DM sans', fontSize: '12px' }} onChange={e => {
                                    setItinerary(e.target.value)
                                }} value={itinerary} /> */}

                <div className="accordion">
                  <Accordions
                    title={"Itinerary"}
                    item={itinerary}
                    setItem={setItinerary}
                    update={updateTrek}
                  />
                </div>
              </Form.Group>
            </Row>

            <Row>
              <Form.Group>
                {/* <Form.Label style={{ fontFamily: 'DM sans', fontWeight: 'bold', fontSize: '15px' }}>Notes </Form.Label>
                                <Form.Control required type="text" as="textarea" placeholder="Notes" style={{ fontFamily: 'DM sans', fontSize: '12px' , height:"100px" }} onChange={e => setNotes(e.target.value)} value={notes} /> */}

                <div className="accordion">
                  <Accordions
                    title={"Notes"}
                    item={notes}
                    setItem={setNotes}
                    update={updateTrek}
                  />
                </div>
              </Form.Group>
            </Row>

            <br />
            <Button
              type="submit"
              size="sm"
              variant="outline-primary"
              style={{ fontFamily: "DM sans", fontSize: "12px" }}
            >
              Add
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
