import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Button,
  Table,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import swal from "sweetalert";

export const GetAllQuery = () => {
  const [data, setdata] = useState();
  const [deleteId, setdeleteId] = useState();
  const [updatedId, setupdatedId] = useState();

  useEffect(() => {
    getdata();
  }, [deleteId, updatedId]);

  const getdata = () => {
    axios.get(`${process.env.REACT_APP_API}/query`).then(function (res) {
      setdata(res.data);
    });
  };

  const DeleteList = (id) => {
    axios
      .delete(`${process.env.REACT_APP_API}/query/${id}`)
      .then(function (res) {
        setdeleteId(res.data);
        swal({
          title: "Delete",
          text: "Delete Successfully!!! ",
          icon: "success",
        });
      });
  };


  return (
    <Container style={{ marginTop: "50px" }}>
      <Row>
        <Col style={{ padding: "0px" }}>
          <h1
            style={{
              fontFamily: "DM Sans",
              fontSize: 20,
              textAlign: "center",
              marginBottom: 20,
              fontWeight: "bold",
            }}
          >
            All Query Details{" "}
          </h1>
          <Table
            bordered
            style={{ fontFamily: "DM Sans", fontSize: "12px" }}
            responsive
          >
            <thead>
              <tr>
                <th>S.No</th>
                <th>Full Name </th>
                <th>Email</th>
                <th>Contact</th>
                <th>Query</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((el, indx) => (
                <tr key={indx}>
                  <td>{indx++ + 1}</td>
                  <td>{el.fullName}</td>
                  <td>{el.email}</td>
                  <td>{el.contact}</td>
                  <td>{el.message}</td>
                  <td>
                    <Button
                      onClick={() => DeleteList(el._id)}
                      variant="outline-danger"
                    >
                      delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};
