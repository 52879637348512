import React, { useState, useEffect } from "react";
import { Container, Row, Card, Button, Col } from "react-bootstrap";
import axios from "axios";
import { getImageURL } from "../commonJs";

export const About = () => {
  const [data, setdata] = useState([]);

  useEffect(() => {
    getdata();
  }, []);

  const getdata = () => {
    axios.get(`${process.env.REACT_APP_API}/about`).then(function (res) {
      setdata(res.data);
    });
  };

  return (
    <Container fluid style={{ backgroundColor: "#efeeea" }}>
      <Row className="header_row px-5 mb-2">
        <h1 style={{ fontSize: "30px" }}>About</h1>
        <hr className="" />

        {data?.map((el) => {
          return (
            <>
              <Row>
                <p className="mx-aut0 d-block" style={{ fontSize: "20px" }}>
                  {el?.aboutTrek.split("\r\n").map((cancelationPoilicy) => (
                    <p> {cancelationPoilicy} </p>
                  ))}
                </p>
              </Row>
              <Row>
                <img
                  style={{
                    height: "40%",
                    marginBottom: "30px",
                    height: "400px",
                  }}

                  
                  src={ getImageURL(el.image[0])}
                  alt="not found"
                  />
                  {console.log(el)}
              </Row>
            </>
          );
        })}
      </Row>
    </Container>
  );
};
