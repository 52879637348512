import React, { useEffect, useState } from "react";
import { Container, Row, Card, Button, Col, Form } from "react-bootstrap";
import "../Style/homePage.css";
import SearchIcon from "@mui/icons-material/Search";
import { LatestTrek } from "./latestTrek";
import { UpcomingTrek } from "./upcomingTrek";
import { PopularTrek } from "./popularTrek";
import { BestPlace } from "./BestPlaceTrek";
import { Review } from "./review";
import { Blogs } from "./blogs";
import { About } from "./about";
import { CharDham } from "./CharDham";
import axios from "axios";

export const HomePage = () => {
  const [allTrek, setAllTrek] = useState([]);
  const [search, setSearch] = useState("");

  const getTrekData = () => {
    axios.get(`${process.env.REACT_APP_API}/trek`).then(function (res) {
      setAllTrek(res.data);
    });
  };

  useEffect(() => {
    getTrekData();
  }, []);

  return (
    <>
      <Container fluid style={{ padding: "0px" }}>
        <div className="row mainPoster">
          <div className="row align-middle">
            <div className="col-lg-4 offset-lg-4 col-sm-8 offset-sm-2">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search treks"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <div className="input-group-append">
                  <button
                    className="btn text-secondary border-left"
                    type="button"
                    style={{
                      borderRadius: "0px 5px 5px 0px",
                      background: "white",
                    }}
                  >
                    <SearchIcon />
                  </button>
                </div>

                {search && (
                  <div className="input-group mb-3">
                    <ul
                      className="form-control"
                      style={{
                        background: "white",
                        borderRadius: "4px",
                        padding: "10px",
                        listStyleType: "none",
                      }}
                    >
                      {allTrek.filter((obj) =>
                        obj.title.toLowerCase().includes(search.toLowerCase())
                      ).length ? (
                        allTrek.map((el, index) => {
                          if (
                            search &&
                            el.title
                              .toLowerCase()
                              .includes(search.toLowerCase())
                          ) {
                            return (
                              <a href={`latestTrek/${el._id}`}>
                                <li style={{ textDecoration: "none" }}>
                                  {el.title}
                                </li>
                              </a>
                            );
                          }
                        })
                      ) : (
                        <li>No result found</li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Col
          style={{
            backgroundColor: "#31353d",
            color: "white",
            height: "70px",
            padding: "15px",
          }}
        >
          <h3 style={{ textAlign: "center" }}>
            Go after what you want, but enjoy the trip{" "}
          </h3>
        </Col>

        <LatestTrek />
        <CharDham />
        <UpcomingTrek />
        <PopularTrek />
        <BestPlace />
        <Review />
        <Blogs />
        <About />
      </Container>
    </>
  );
};
