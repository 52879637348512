import React, { useState, useEffect } from "react";
import { Container, Row, Card, Button, Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { Link, useParams, useLocation } from "react-router-dom";

export const Trek = () => {
  const [data, setdata] = useState();
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    getdata();
  }, [location]);

  const getdata = () => {
    axios
      .post(`${process.env.REACT_APP_API}/trek/trekByCategory`, {
        category: id === "all" ? "" : id,
      })
      .then(function (res) {
        setdata(res.data);
      });
  };

  return (
    <Container
      className="px-5 py-3"
      fluid
      style={{ backgroundColor: "#efeeea" }}
    >
      <h3 className="firstUperCase">{id} Trek</h3>
      <hr />
      <Row className={`px-5 ${!data?.length > 0 && "justify-content-center"}`}>
        {data?.length > 0 ? (
          data?.map((el, idx) => {
            return (
              <div className="col-lg-3 mb-3">
                <div>
                  <Card style={{ width: "18rem" }}>
                    <Card.Img
                      style={{ height: "190px" }}
                      variant="top"
                      src={
                        el.image
                          ? el.image[0]
                          : "https://tse2.mm.bing.net/th?id=OIP.yHrP1XP9nGoetObf102rvwHaFE&pid=Api&P=0"
                      }
                    />
                    <Card.Body>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          fontSize: "13px",
                          color: "gray",
                        }}
                      >
                        <p>Difficulty Level | {el.level}</p>{" "}
                        <p>Duration | {el.days} days</p>
                      </span>
                      <Card.Title>{el.title}</Card.Title>
                      <Card.Text>
                        {el.fee} <br />
                      </Card.Text>
                      <Link to={`/latestTrek/${el._id}`}>
                        <Button style={{ width: "100%" }} variant="primary">
                          View Details
                        </Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            );
          })
        ) : (
          <div className="col-lg-6">
            <div>
              <Card style={{}}>
                <Card.Img
                  style={{ height: "80%" }}
                  variant="top"
                  src={
                    "https://tse2.mm.bing.net/th?id=OIP.yHrP1XP9nGoetObf102rvwHaFE&pid=Api&P=0"
                  }
                />
                <Card.Body>
                  <Card.Title className="firstUperCase">
                    {id} Trek Comming Soon
                  </Card.Title>
                  <Card.Text>
                    <Link to="/contactUs">Contact US for more Information</Link>
                    <br />
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        )}
      </Row>
    </Container>
  );
};
