import logo from './logo.svg';
import './App.css';
import {Route , Routes , Navigate} from "react-router-dom" 
import { Navbarmenu } from './page/navbar';
import  {HomePage} from './page/homePage';

import Footer from './page/footer';
import {Login} from './page/login';
import { SignUp } from './page/signUp';

import { AdminLogin } from './Component/admin/adminLogin';
import { AdminDashboard } from './Component/admin/adminDashboard';
import { Detail } from './page/detail';
import {Trek} from './page/trek';
import { GetAllContactUs } from './Component/admin/getAllContactUs';
import { GetAllQuery } from './Component/admin/getAllQuery';
import { ContactUs } from './page/contactUs';
import { Query } from "./page/query"
import { AdminTrek } from './Component/admin/trek';
import { AddTrek } from './Component/admin/addTerk';
import { Blog } from './Component/admin/Blog';
import { About } from './Component/admin/About';


function App() {
  return (
    <div className="App" style={{backgroundColor : "#efeeea"}}>
        <Navbarmenu/>
        <Routes>
          <Route path='/' element={<HomePage/>}> </Route>
          <Route path='/SignUp' element={<SignUp />} ></Route>
          <Route path='/login' element={<Login />} ></Route>
          <Route path='/adminlogin' element={<AdminLogin />} ></Route>
          <Route path='/treks/:id' element={<Trek />} ></Route>
          <Route path='/latestTrek/:id' element={<Detail />} ></Route>
          <Route path='/upcomingTrek/:id' element={<Detail />} ></Route>
          <Route path='/popularTrek/:id' element={<Detail />} ></Route>
          <Route path='/ContactUs' element={<ContactUs />} ></Route>
          <Route path='/query' element={<Query />} ></Route>

          <Route path='/adminDashboard' element={< IsAdmin><AdminDashboard /> </IsAdmin>} >

            <Route path='bestplace' element={<AdminTrek />} ></Route>
            <Route path='addbestplace' element={<AddTrek />} ></Route>
            <Route path='chardham' element={<AdminTrek />} ></Route>
            <Route path='addchardham' element={<AddTrek />} ></Route>
            <Route path='schoolCollege' element={<AdminTrek />} ></Route>
            <Route path='addschoolCollege' element={<AddTrek />} ></Route>
            <Route path='latest' element={<AdminTrek />} ></Route>
            <Route path='addlatest' element={<AddTrek />} ></Route>
            <Route path='upcoming' element={<AdminTrek />} ></Route>
            <Route path='addUpcoming' element={<AddTrek />} ></Route>
            <Route path='popular' element={<AdminTrek />} ></Route>
            <Route path='addPopular' element={<AddTrek />} ></Route>

            <Route path='kumaon' element={<AdminTrek />} ></Route>
            <Route path='addKumaon' element={<AddTrek />} ></Route>

            <Route path='garhwal' element={<AdminTrek />} ></Route>
            <Route path='addGarhwal' element={<AddTrek />} ></Route>

            <Route path='himachal' element={<AdminTrek />} ></Route>
            <Route path='addHimachal' element={<AddTrek />} ></Route>
            
            <Route path='Contactus' element={<GetAllContactUs />} ></Route>
            <Route path='Query' element={<GetAllQuery />} ></Route>
            <Route path='blog' element={<Blog />} ></Route>
            <Route path='about' element={<About />} ></Route>


          </Route>

        </Routes>
        <Footer />
    </div>
  );
}

function IsAdmin({ children }) {
  return (
    localStorage.getItem('admintoken') ? children : <Navigate to={"/"} />
  )
}

export default App;
