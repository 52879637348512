import React, { useState, useEffect } from "react";
import { Container, Row, Card, Button, Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { getImageURL } from "../commonJs";

export const PopularTrek = () => {
  const [data, setdata] = useState();

  useEffect(() => {
    getdata();
  }, []);

  const getdata = () => {
    axios
      .post(`${process.env.REACT_APP_API}/trek/trekByCategory`, {
        category: "popular",
      })
      .then(function (res) {
        setdata(res.data);
      });
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container
      className="px-5 py-3"
      fluid
      style={{ backgroundColor: "#efeeea" }}
    >
      <h3>Popular Trek</h3>
      <hr />
      <Row>
        <Slider {...settings}>
          {data?.map((el, idx) => {
            return (
              <Row>
                <div>
                  <Card style={{ width: "18rem" }}>
                    <Card.Img
                      style={{ height: "190px" }}
                      variant="top"
                      src={getImageURL(el.image[0])  }
                    />

                    <Card.Body>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          fontSize: "13px",
                          color: "gray",
                        }}
                      >
                        <p>Difficulty Level | {el.level}</p>{" "}
                        <p>Duration | {el.days} days</p>
                      </span>
                      <Card.Title>{el.title}</Card.Title>
                      <Card.Text>
                        {el.heading} <br />
                        <b>
                          {" "}
                          <p style={{ color: "black" }}>₹{el.fee}</p>
                        </b>
                      </Card.Text>
                      <Link to={`/popularTrek/${el._id}`}>
                        <Button style={{ width: "100%" }} variant="primary">
                          View Details
                        </Button>
                      </Link>
                    </Card.Body>
                  </Card>
                </div>
              </Row>
            );
          })}
        </Slider>
      </Row>
    </Container>
  );
};
