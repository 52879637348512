import React, { useState } from "react";
import { Nav, Tab, Row, Col, Button } from "react-bootstrap";
import "./css/adminDashboard.css";
import { Outlet, Link, Navigate } from "react-router-dom";
import {
  FaHome,
  FaSignOutAlt,
  FaWpforms,
  FaClipboardList,
} from "react-icons/fa";

export const AdminDashboard = () => {
  const adminToken = localStorage.getItem("adminToken");

  const UserHandelDelete = () => {
    localStorage.removeItem("admintoken");
  };

  // if (!adminToken) {
  //     return <Navigate to='/adminLogin' />
  // }

  // if (logout) {
  //     return <Navigate to="/adminLogout" />
  // }
  return (
    <>
      <h1
        style={{
          textAlign: "center",
          background: "#000",
          padding: "20px",
          color: "#fff",
          fontFamily: "Dm Sans",
          marginBottom: "-10px",
        }}
      >
        Admin Dashboard
      </h1>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col md={2} style={{ background: "#000", minHeight: "700px" }}>
            <Nav
              style={{ marginLeft: "30px" }}
              variant="pills"
              className="flex-column"
            >
              <Nav.Item>
                <Nav.Link>
                  <Link
                    to="/"
                    className="sidebarOptions"
                    style={{ textDecoration: "none", fontSize: "15px" }}
                  >
                    <FaHome style={{ marginTop: "-4px" }} /> Home
                  </Link>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link>
                  <Link
                    to="/adminDashboard/latest"
                    className="sidebarOptions"
                    style={{ textDecoration: "none", fontSize: "15px" }}
                  >
                    <FaClipboardList style={{ marginTop: "-4px" }} /> Latest
                    Trek
                  </Link>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link>
                  <Link
                    to="/adminDashboard/upcoming"
                    className="sidebarOptions"
                    style={{ textDecoration: "none", fontSize: "15px" }}
                  >
                    <FaClipboardList style={{ marginTop: "-4px" }} /> Upcoming
                    Trek
                  </Link>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link>
                  <Link
                    to="/adminDashboard/popular"
                    className="sidebarOptions"
                    style={{ textDecoration: "none", fontSize: "15px" }}
                  >
                    <FaClipboardList style={{ marginTop: "-4px" }} /> Popular
                    Trek
                  </Link>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link>
                  <Link
                    to="/adminDashboard/kumaon"
                    className="sidebarOptions"
                    style={{ textDecoration: "none", fontSize: "15px" }}
                  >
                    <FaClipboardList style={{ marginTop: "-4px" }} /> Kumaon
                    Trek
                  </Link>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link>
                  <Link
                    to="/adminDashboard/garhwal"
                    className="sidebarOptions"
                    style={{ textDecoration: "none", fontSize: "15px" }}
                  >
                    <FaClipboardList style={{ marginTop: "-4px" }} /> Garhwal
                    Trek
                  </Link>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link>
                  <Link
                    to="/adminDashboard/himachal"
                    className="sidebarOptions"
                    style={{ textDecoration: "none", fontSize: "15px" }}
                  >
                    <FaClipboardList style={{ marginTop: "-4px" }} /> Himachal
                    Trek
                  </Link>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link>
                  <Link
                    to="/adminDashboard/chardham"
                    className="sidebarOptions"
                    style={{ textDecoration: "none", fontSize: "15px" }}
                  >
                    <FaClipboardList style={{ marginTop: "-4px" }} /> Char Dham{" "}
                  </Link>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link>
                  <Link
                    to="/adminDashboard/bestplace"
                    className="sidebarOptions"
                    style={{ textDecoration: "none", fontSize: "15px" }}
                  >
                    <FaClipboardList style={{ marginTop: "-4px" }} /> Best
                    Places{" "}
                  </Link>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link>
                  <Link
                    to="/adminDashboard/schoolCollege"
                    className="sidebarOptions"
                    style={{ textDecoration: "none", fontSize: "15px" }}
                  >
                    <FaClipboardList style={{ marginTop: "-4px" }} />{" "}
                    School/College Trek{" "}
                  </Link>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link>
                  <Link
                    to="/adminDashboard/Contactus"
                    className="sidebarOptions"
                    style={{ textDecoration: "none", fontSize: "15px" }}
                  >
                    <FaClipboardList style={{ marginTop: "-4px" }} /> Contact
                  </Link>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link>
                  <Link
                    to="/adminDashboard/Query"
                    className="sidebarOptions"
                    style={{ textDecoration: "none", fontSize: "15px" }}
                  >
                    <FaClipboardList style={{ marginTop: "-4px" }} /> Query
                  </Link>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link>
                  <Link
                    to="/adminDashboard/blog"
                    className="sidebarOptions"
                    style={{ textDecoration: "none", fontSize: "15px" }}
                  >
                    <FaClipboardList style={{ marginTop: "-4px" }} /> Blog
                  </Link>
                </Nav.Link>
              </Nav.Item>

              <Nav.Item>
                <Nav.Link>
                  <Link
                    to="/adminDashboard/about"
                    className="sidebarOptions"
                    style={{ textDecoration: "none", fontSize: "15px" }}
                  >
                    <FaClipboardList style={{ marginTop: "-4px" }} /> About
                  </Link>
                </Nav.Link>
              </Nav.Item>

              <Button
                className="Sidebarlogoutbtn"
                onClick={UserHandelDelete}
                style={{ fontSize: "15px" }}
              >
                Log Out <FaSignOutAlt style={{ marginTop: "-3px" }} />
              </Button>
            </Nav>
          </Col>
          <Col md={10}>
            <Outlet />
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};
