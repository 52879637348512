import React, { useState } from "react";
import { Container, Row, Button, Form, Col } from "react-bootstrap";
import axios from "axios";
import swal from "sweetalert";

export const Query = () => {
  const [fullName, setfullname] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const [data, setdata] = useState();
  const [validated, setValidated] = useState(false);

  const Contactdata = {
    fullName: fullName,
    contact: contact,
    email: email,
    query: query,
  };

  const HandelSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      setValidated(true);
      event.stopPropagation();
    } else {
      axios
        .post(`${process.env.REACT_APP_API}/query`, Contactdata)
        .then(function (res) {
          setdata(res.data);
        });
      swal({ title: "Send", text: "send Successfully!!! ", icon: "success" });
    }
    setfullname("");
    setContact("");
    setEmail("");
    setQuery("");
  };

  return (
    <Container>
      <Row>
        <div className="contactUS-main-div">
          <Col style={{ marginTop: "50px" }}>
            <h1
              style={{
                fontSize: "25px",
                fontFamily: "DM Sans",
                fontWeight: "bold",
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              QUERY
            </h1>
            <Form
              noValidate
              validated={validated}
              className="mx-auto d-block"
              onSubmit={HandelSubmit}
            >
              <Form.Group className="mx-auto d-block" as={Col} md="10">
                <Form.Label style={{ fontFamily: "DM Sans", fontSize: "12px" }}>
                  Full Name
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Full Name"
                  style={{ fontFamily: "DM Sans", fontSize: "12px" }}
                  onChange={(e) => setfullname(e.target.value)}
                  value={fullName}
                />
              </Form.Group>

              <Form.Group className="mx-auto d-block" as={Col} md="10">
                <Form.Label style={{ fontFamily: "DM Sans", fontSize: "12px" }}>
                  Email
                </Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Email"
                  style={{ fontFamily: "DM Sans", fontSize: "12px" }}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </Form.Group>

              <Form.Group className="mx-auto d-block" as={Col} md="10">
                <Form.Label style={{ fontFamily: "DM Sans", fontSize: "12px" }}>
                  Contact
                </Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Contact"
                  style={{ fontFamily: "DM Sans", fontSize: "12px" }}
                  onChange={(e) => setContact(e.target.value)}
                  value={contact}
                />
              </Form.Group>

              <Form.Group className="mx-auto d-block" as={Col} md="10">
                <Form.Label style={{ fontFamily: "DM Sans", fontSize: "12px" }}>
                  Query
                </Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  placeholder="Query"
                  rows={1}
                  style={{
                    fontFamily: "DM Sans",
                    fontSize: "12px",
                    height: "90px",
                  }}
                  onChange={(e) => setQuery(e.target.value)}
                  value={query}
                />
              </Form.Group>
              <br></br>

              <Button
                style={{ width: "83%", marginBottom: "50px" }}
                className="mx-auto d-block"
                type="submit"
              >
                Submit
              </Button>
            </Form>
          </Col>
        </div>
      </Row>
    </Container>
  );
};
