import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";

export const Blogs = () => {
  const [data, setdata] = useState();

  useEffect(() => {
    getdata();
  }, []);

  const getdata = () => {
    axios.get(`${process.env.REACT_APP_API}/blog`).then(function (res) {
      setdata(res.data);
    });
  };

  return (
    <Container fluid style={{ backgroundColor: "#efeeea" }}>
      <h1 style={{ fontSize: "30px", marginLeft: "50px" }}>Blogs</h1>
      <hr className="mx-5" />
      <Row>
        <Col>
          {data ? (
            data?.map((el) => {
              let url = el.blogUrl?.split("watch?v=")[1];
              return (
                <div>
                  <iframe
                    style={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      with: "90%",
                    }}
                    className="mx-auto d-block"
                    src={`https://www.youtube.com/embed/${url}`}
                    width={"60%"}
                    height={"300px"}
                    allow="autoplay; encrypted-media"
                    allowFullScreen
                    title="video"
                  />
                </div>
              );
            })
          ) : (
            <div>
              <iframe
                style={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  with: "90%",
                }}
                className="mx-auto d-block"
                src="https://www.youtube.com/embed/UhDVx4rk_70"
                width={"60%"}
                height={"300px"}
                allow="autoplay; encrypted-media"
                allowFullScreen
                title="video"
              />
            </div>
          )}

          <a href="https://www.youtube.com/@UttrakhandHikes" target="blank">
            <button className="btn btn-primary mx-auto d-block">
              See All blogs
            </button>
          </a>
        </Col>
      </Row>
    </Container>
  );
};
